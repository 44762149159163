<template>
  <auth-template title="Login to Flight Planning">
    <form v-on:submit.prevent="login()">
      <div class="field">
        <div class="control">
          <b-input type="email" maxlength="40" placeholder="Your Email"
                   v-model="data.body.email"></b-input>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <b-input type="password" placeholder="Your Password" v-model="data.body.password"
                   password-reveal></b-input>
        </div>
      </div>

      <div class="field">
        <label class="checkbox">
          <input type="checkbox">
          Remember me
        </label>
      </div>

      <button type="submit" class="button is-block is-info is-fullwidth">
        Login <i class="fa fa-sign-in" aria-hidden="true"></i>
      </button>
      <h2 class="is-size-4" v-show="error" style="color:red; word-wrap:break-word;">{{ error }}</h2>
    </form>
    <p class="has-text-grey forgot-link">
      <a href="/auth/forgot-password">Forgot Password</a>
    </p>
  </auth-template>
</template>

<script>
import AuthTemplate from "@/components/auth/AuthTemplate";
export default {
  components: {AuthTemplate},
  data() {
    return {
      context: 'login context',
      data: {
        body: {
          username: '',
          password: ''
        },
        rememberMe: false,
        fetchUser: true
      },
      error: null
    };
  },
  methods: {
    login() {
      // var redirect = this.$auth.redirect();
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.$auth.login({
            body: this.data.body, // Vue-resource
            rememberMe: this.data.rememberMe,
            fetchUser: this.data.fetchUser
          })
          .then(() => {
            loadingComponent.close();
          }, () => {
            this.error = 'Wrong user data, try again';
            loadingComponent.close();
          });
    }
  }
}
</script>

<style scoped>
.forgot-link{
  margin-top: 1rem;
}
</style>
